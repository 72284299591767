import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown } from "antd";

import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { FaUserLarge } from "react-icons/fa6";
import { Menuitems } from "./Menuitems";
import { useDispatch, useSelector } from "react-redux";
import { clearAllRedux } from "../Store/B2bslice";
import { Images } from "../Controller";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const heading = useSelector((state) => state.admin.heading);
  const selectedTab = useSelector((state) => state.admin.selectedTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // setselectedTab(getLocalStorageItem("selectedTab"));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {
  //   FetchWallet()
  //     .then((res) => {
  //       if (res.status) {
  //        dispatch(setWalletBal(res.walletBalance))
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedTab]);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [windowwidth]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearAllRedux());
    navigate("/");
  };
  const items = [
    // {
    //   key: "3",
    //   label: (
    //     <div className="w-48">
    //       <div className="text-sm flex justify-start items-center">
    //         <AiOutlineWallet size={20} className="mr-3 text-sm text-primary" />
    //         <p> Wallet Balance</p>
    //       </div>
    //       <p className="font-bold text-[16px] mb-4">Rs. {walletBal}</p>
    //       <hr />
    //     </div>
    //   ),
    // },
    {
      key: "1",
      label: (
        <>
          <p
            onClick={() => navigate("/change-password")}
            className="font-medium mb-4"
          >
            Change Password
          </p>
          <hr />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <p onClick={handleLogout} className="font-medium">
          Sign Out
        </p>
      ),
    },
  ];

  return (
    <>
      <Layout hasSider className="min-h-screen h-full bg-primary  sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className="my-10"
          collapsed={collapsed}
        >
          <div className="flex gap-4 mt-4 justify-start items-center">
                  {" "}
                  <img
                    src={Images.WebsiteLogo}
                    className="w-28 m-auto"
                    alt="Weblogo"
                  />
                </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedTab]}
            items={Menuitems()}
          />
        </Sider>
        <Layout className="w-full">
          <Header
            style={{
              marginLeft: collapsed ? 75 : 230,
              // background:"linear-gradient(169deg, rgb(245, 14, 14) 0%, rgb(255 255 255) 0%, rgb(33, 111, 242) 100%, rgb(227, 9, 9) 100%)"
            }}
            className=" bg-darkbg2 mt-4 flex justify-between items-center h-20"
          >
            <div className=" flex flex-wrap justify-start gap-x-3 mx-4  items-center">
              {collapsed ? (
                <p>
                  {" "}
                  <BsArrowRight
                    size={30}
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-white cursor-pointer"
                  />
                </p>
              ) : (
                ""
              )}
            </div>

            <Dropdown
              className="dropdownMenu cursor-pointer mr-12"
              menu={{ items }}
              trigger={["click"]}
            >
              <p className="bg-darkbg p-4 rounded-full">
                <FaUserLarge color="white"/>
              </p>
            </Dropdown>
            {/* </div> */}
          </Header>
          <Content
            className={`bg-primary h-full p-3 md:p-5 ${
              collapsed ? "ml-[75px]" : "ml-[230px]"
            }`}
          >
            <div className="bg-darkbg mb-4 p-4 text-white font-bold ">
              <p>{heading}</p>
            </div>
              <div className="bg-primary h-full min-h-screen max-h-screen">
              {children}
              </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ProjectLayout;
