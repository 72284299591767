import { NavLink } from "react-router-dom";

import { GiCaveman, GiGamepad } from "react-icons/gi";
import { PiMoneyBold } from "react-icons/pi";

import {  MdDashboard, MdPayments } from "react-icons/md";
import { FaPlaneDeparture, FaRupeeSign } from "react-icons/fa";
import {  FaUser } from "react-icons/fa6";
import { VscGraphLine } from "react-icons/vsc";
import { LiaGamepadSolid } from "react-icons/lia";
import { AiFillSetting } from "react-icons/ai";
import { CiBaseball } from "react-icons/ci";

export const Menuitems = () => {
  const list = [
    {
      key: "1",
      icon: <MdDashboard/>,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "6",
      icon: <FaUser />,
      label: (
        <NavLink to={"/user-list"}>
          <p>User List</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      icon: <GiGamepad  />,
      label: (
        <NavLink to={"/game-list"}>
          <p>Games</p>
        </NavLink>
      ),
    },
    {
      key: "8",
      icon: <FaPlaneDeparture />,
      label: (
        <NavLink>
          <p>Aviator</p>
        </NavLink>
      ),
      children: [
        {
          key: "8.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={"/aviator/settings"}>
              <p>Setting</p>
            </NavLink>
          ),
        }, {
          key: "8.2",
          icon: <VscGraphLine />,
          label: (
            <NavLink to={"/aviator/current-room-stats"}>
              <p>Current Room statics</p>
            </NavLink>
          ),
        }, {
          key: "8.3",
          icon: <LiaGamepadSolid />,
          label: (
            <NavLink to={"/aviator/rooms"}>
              <p>Rooms</p>
            </NavLink>
          ),
        },
      ]
    },
    {
      key: "9",
      icon: <GiCaveman />,
      label: (
        <NavLink>
          <p>Mines</p>
        </NavLink>
      ),
      children: [
        {
          key: "9.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={"/mines/settings"}>
              <p>Mines Setting</p>
            </NavLink>
          ),
        },
         {
          key: "9.2",
          icon: <VscGraphLine />,
          label: (
            // <NavLink to={"/user-list"} state={{from:"mines"}}>
            //   <p>Latest</p>
            // </NavLink>
            <NavLink to={"/mines/bets"} state={{from:"mines"}}>
              <p>Latest Bets</p>
            </NavLink>
          ),
        },

      ]
    },
    {
      key: "10",
      icon: <CiBaseball />,
      label: (
        <NavLink>
          <p>Plinko</p>
        </NavLink>
      ),
      children: [
        {
          key: "10.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={"/plinko/settings"}>
              <p>Plinko Setting</p>
            </NavLink>
          ),
        },
         {
          key: "10.2",
          icon: <VscGraphLine />,
          label: (
            // <NavLink to={"/user-list"} state={{from:"plinko"}}>
            //   <p>Bets</p>
            // </NavLink>
            <NavLink to={"/plinko/bets"} state={{from:"plinko"}}>
              <p>Latest Bets</p>
            </NavLink>
          ),
        },

      ]
    },
    {
      key: "3",
      icon: <MdPayments />,
      label: (
        <NavLink to={"/payment-setting"}>
          <p>Payment Setting</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      icon: <FaRupeeSign />,
      label: (
        <NavLink to={"/fund-request"}>
          <p>Fund Request</p>
        </NavLink>
      ),
    },
    {
      key: "5",
      icon: <PiMoneyBold />,
      label: (
        <NavLink to={"/withdrawal-request"}>
          <p>Withdrawal Request</p>
        </NavLink>
      ),
    },
    {
      key: "7",
      icon: <PiMoneyBold />,
      label: (
        <NavLink to={"/comission"}>
          <p>Referal Comission</p>
        </NavLink>
      ),
    },
  ];
  return list;
};
